
// 获取下单定制款所有规格拼接
const getPartsSuk = (orderParts, ids = []) => {
  let suk = ""
  if (typeof ids === "string") {
    ids = ids.split("-")
  }
  if (!orderParts) return
  orderParts.map((item) => {
    item.orderPartsAttr.map((partsAttr) => {
      ids.map((id) => {
        if (id == partsAttr.attr_value_id) {
          let attr_name = partsAttr.attr_name ? partsAttr.attr_name + "-" + partsAttr.attr_value_name : partsAttr.attr_value_name
          suk += item.name + "：" + attr_name + "、"
        }
      })
    })
  })
  suk = suk.slice(0, suk.length - 1)
  return suk
}
// 获取下单定制款所有规格数组
const getPartsSukList = (orderParts, ids = []) => {
  let sukList = []
  if (typeof ids === "string") {
    ids = ids.split("-")
  }
  if (!orderParts) return
  orderParts.map((item) => {
    item.orderPartsAttr.map((partsAttr) => {
      ids.map((id) => {
        if (id == partsAttr.attr_value_id) {
          let attr_name = partsAttr.attr_name ? partsAttr.attr_name + "-" + partsAttr.attr_value_name : partsAttr.attr_value_name
          let skuItem = {
            partsName: item.name,
            attrName: attr_name,
            price: partsAttr.price
          }
          sukList.push(skuItem)
        }
      })
    })
  })
  return sukList
}
// 获取订单状态
const getOrderStatus = (orderInfo) => {
  if(!Object.keys(orderInfo).length) return
  if(!orderInfo.customizedInfo) {
    return orderInfo.status
  }
  let status = orderInfo.customizedInfo.status.value
  let commentStatus = orderInfo.customizedInfo.comment_status.value
  let deliveryStatus = orderInfo.customizedInfo.delivery_status.value
  // if (status < 40 || status > 50) {
  //   status = 20
  // }
  if (status === 40) {
    if (status === 40 && deliveryStatus === 0) {
      status = 40 // 待送货
    }
    if (status === 40 && deliveryStatus === 10 && !orderInfo.repair) {
      status = 61 // 待验收
    }
    if (status === 40 && deliveryStatus === 20) {
      status = 31 // 待收货
    }
  }
  if (status === 50) {
    if (status === 50 && commentStatus !== 10) {
      status = 50 // 已完成
    }
    if (status === 50 && commentStatus === 10) {
      status = 55 // 待评价
    }
  }
  return status
}

// 获取下单定制款所有规格拼接
const getPartsSukForProduct = (orderParts, ids = []) => {
  let suk = ""
  if (typeof ids === "string") {
    ids = ids.split("-")
  }
  if (!orderParts) return
  orderParts.map(firstItem => {
    firstItem.productAttr.map(twoItem => {
      twoItem.productAttrVal.map(threeItem =>{
        ids.map(idItem => {
          if(parseInt(idItem) === threeItem.id) {
            let attr_name = twoItem.name ? twoItem.name + "-" + threeItem.material_name : threeItem.material_name
            suk += firstItem.name + "：" + attr_name + "、"
          }
        })
      })
    })
  })
  return suk
}

const getPartsSukForProductArr = (orderParts, ids = []) => {
  let sukList = []
  if (typeof ids === "string") {
    ids = ids.split("-")
  }
  if (!orderParts) return
  orderParts.map(firstItem => {
    firstItem.productAttr.map(twoItem => {
      twoItem.productAttrVal.map(threeItem =>{
        ids.map(idItem => {
          if(parseInt(idItem) === threeItem.id) {
            let attr_name = twoItem.name ? twoItem.name + "-" + threeItem.material_name : threeItem.material_name
            let skuItem = {
              partsName: firstItem.name,
              attrName: attr_name,
              price: threeItem.price
            }
            sukList.push(skuItem)
          }
        })
      })
    })
  })
  return sukList
}

export {
  getPartsSuk,
  getPartsSukList,
  getOrderStatus,
  getPartsSukForProduct,
  getPartsSukForProductArr
}

import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4fe1786e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "del-tips-container"
};
var _hoisted_2 = {
  class: "tips-content"
};
var _hoisted_3 = {
  class: "flex-between"
};
var _hoisted_4 = {
  class: "tips-title"
};
var _hoisted_5 = {
  class: "content flex-center"
};
var _hoisted_6 = {
  class: "tips-footer flex-center"
};
import { ref, watch } from "vue";
export default {
  __name: 'index',
  props: {
    content: {
      type: String,
      default: ""
    }
  },
  emits: ["close", "confirm"],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emits = _ref.emit;
    var props = __props;
    var isShow = ref(false);
    watch(isShow, function (val) {
      if (val) {
        open();
      }
    });
    var open = function open() {
      isShow.value = true;
    };
    var confirm = function confirm() {
      emits("confirm");
      close();
    };
    var close = function close() {
      isShow.value = false;
    };
    expose({
      open: open
    });
    return function (_ctx, _cache) {
      return _unref(isShow) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("header", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("myAddressList.warmReminder")), 1), _createElementVNode("div", {
        class: "close pointer",
        onClick: close
      }, _toDisplayString(_ctx.$t("myAddressList.close")), 1)]), _createElementVNode("section", _hoisted_5, _toDisplayString(__props.content), 1), _createElementVNode("footer", _hoisted_6, [_createElementVNode("div", {
        class: "cancel-btn flex-center pointer mr10",
        onClick: close
      }, _toDisplayString(_ctx.$t("myAddressList.cancel")), 1), _createElementVNode("div", {
        class: "confirm-btn flex-center pointer ml10",
        onClick: confirm
      }, _toDisplayString(_ctx.$t("myAddressList.confirm")), 1)])])])) : _createCommentVNode("", true);
    };
  }
};